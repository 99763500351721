import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from "@suid/material";
import i18next, { changeLanguage, t } from "i18next";
import {
    type Component,
    createMemo,
    createSignal,
    type JSX,
    lazy,
    Match,
    onMount,
    type Setter,
    Show,
    Switch,
    type
    ParentComponent,
} from "solid-js";
import { type LoginDialogProps } from "./Types";
import prowise_svg from "../../assets/prowise.svg";
import { config } from "../../config";
import { type BCP47 } from "../../utilities/defaultLocales";
import { MarkdownLink } from "../../utilities/Parser/MarkdownLink";
import { isPdfJsSupported } from "../../utilities/UserAgentHelper";
import { AppFrame } from "../AppFrame";
import { DebugErrors } from "../DebugErrors";
import { LocaleMenu } from "../LocaleMenu";

const PdfModalDialog = lazy(async () => import("../PdfModalDialog/PdfModalDialog"));
const MessageModalDialog = lazy(async () => import("../MessageModalDialog/MessageModalDialog"));
const SuidLink = lazy(async () => import("@suid/material/Link"));

const PrivacyTermsModal: Component<{ pdfResource?: string; open?: boolean; onClose?: () => void }> = (props) => {
    const theme = useTheme();
    // Try and open the PDF modal if supported.
    // Note that since PdfModalDialog contains imports that may break older browsers,
    // we have a separate modal for error notifications.
    return <Show when={isPdfJsSupported()} fallback={
        <MessageModalDialog
            titleButton={
                <Button
                    href={props.pdfResource}
                    variant="contained"
                    color="secondary"
                    sx={theme.mixins.button}
                    title={t("general.btn.download_document_pdf")}
                >
                    {t("general.btn.download_document_pdf")}
                </Button>
            }
            open={!!props.pdfResource}
            onClose={props.onClose}
        >
            {t("error.p.pdf_rendering_unsupported")}
        </MessageModalDialog>}><PdfModalDialog
            pdfResource={props.pdfResource}
            open={!!props.pdfResource}
            onClose={props.onClose}
        /></Show>;
};

const Footer: Component<{ setPdf: Setter<string | undefined>; padRight?: boolean }> = (props) => {
    const theme = useTheme();

    const version = config.version.env && config.version.env !== "production"
        ? `${config.version.env} - ${config.version.number}`
        : config.version.number;

    return (<footer>
        <Typography sx={theme.mixins.typography} variant="caption2" as="span">
            <Show when={config.debug.sandbox}>
                <SuidLink sx={{ ...theme.mixins.link, pr: 1 }} href="/test">debug page</SuidLink>
            </Show>
            {t("general.p.version_{version}", { version })}
        </Typography>
        <MarkdownLink
            variant="caption2"
            color="inherit"
            sx={{ ...theme.mixins.typography, pl: 1.5, pr: props.padRight ? 2.5 : 0 }}
            text={t("general.a.privacy_and_terms")}
            onClick={(event) => { props.setPdf(event.target.href); event.preventDefault(); }}
        />
    </footer>);
};

export const LoginDialog: ParentComponent<LoginDialogProps> = (props) => {
    const theme = useTheme();
    const [pdf, setPdf] = createSignal<string>();

    let frameRef: HTMLElement | undefined;

    // Less than 600px width
    const isSmDownBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));

    // Allow arbitrary height when the default height (including footer that uses 2rem) does not fit.
    // Note: using `calc` in media queries is not according to spec and not allowed in vitest
    const isHeightBreakPoint = useMediaQuery("@media (max-height: 880px)");

    const fullHeight = createMemo(() => isSmDownBreakpoint() || props.fullHeight);
    const fullscreen = createMemo(() => isSmDownBreakpoint() || props.fullscreen);
    // Horizontal changes the layout (typically for chromebooks)
    const isHorizontal = createMemo(() => props.orientation === "horizontal");
    // Alternate height creates full width bars from the logo and action buttons
    const alternateHeight = createMemo(() => isHorizontal() && props.minContentHeight && fullscreen());
    // Alternate width makes the secondary panel "full width"
    const alternateWidth = createMemo(() => isHorizontal() && props.fullWidth && fullscreen());

    const handleClose = (): void => {
        setPdf(undefined);
    };

    // NOTE: Sequential mounts don't do autofocus; `keyed` properties have no effect
    // and we don't have a Dialog to set `disableRestoreFocus` on.
    // Also, due to some reactive properties, the UI is not fully drawn immediately,
    // so we need to delay the actual focus.
    onMount(() => {
        requestAnimationFrame(() => {
            document.title = typeof props.title === "string" ? `${props.title} – ${config.version.productName}` : config.version.productName;
            const element = document?.querySelector<HTMLInputElement>("[autofocus]");
            element?.focus();
        });
    });

    const Head = createMemo((): JSX.Element => <Show
        when={props.showBanner !== false || props.showLanguageSelect !== false}
    >
        <Box
            textAlign={isHorizontal() ? undefined : "center" }
            sx={{
                position: "relative",
                pb: isHorizontal() && alternateHeight() ? 0 : 4,
                mt: isHorizontal() ? 1.5 : 0,
                ml: isHorizontal() && alternateHeight() ? 1.5 : 0,
            }}>
            <Show when={props.showBanner !== false}>
                <img role="banner" height="30px" src={prowise_svg} />
            </Show>
            <Show when={props.showLanguageSelect !== false || props.showBanner === false}>
                <LocaleMenu
                    variant="text"
                    showFlag
                    sx={{
                        position: "absolute",
                        right: theme.spacing(isHorizontal() || fullscreen() ? 0 : -3.5),
                        top: theme.spacing(isHorizontal() || fullscreen() ? 0 : -3.5),
                    }}
                    sxButton={{
                        p: 0,
                        minWidth: isHorizontal() ? "30px" : theme.spacing(5),
                        height: isHorizontal() ? "30px" : theme.spacing(5),
                    }}
                    name="Language"
                    default={i18next.language as BCP47}
                    handleChange={(locale: string) => {
                        // Optionally, check if the chosen language exists in i18n.
                        const changeLang = async (): Promise<void> => {
                            await changeLanguage(locale);
                            document.location.reload();
                        };
                        void changeLang();
                        return locale;
                    }}
                />
            </Show>
        </Box>
    </Show>);
    const Title = createMemo((): JSX.Element => <Show when={props.title}>
        <MarkdownLink data-testid="title" sx={{ ...theme.mixins.typography, mb: 4, overflow: "hidden", overflowWrap: "break-word", flex: "none" }} variant={props.titleVariant ?? "h2"} align={isHorizontal() ? undefined : "center"} color="primary" text={props.title} />
    </Show>);
    const Link = createMemo((): JSX.Element => <Show when={props.link}>
        <Typography sx={{ ...theme.mixins.typography, mt: -3, mb: 4.5, overflow: "hidden", overflowWrap: "break-word", flex: "none" }} variant="body2" align={isHorizontal() ? undefined : "center"} color="primary">{props.link}</Typography>
    </Show>);
    const Description = createMemo((): JSX.Element => <Show when={typeof props.description === "string"} fallback={props.description}>
        <MarkdownLink sx={{ ...theme.mixins.typography, mb: 3, overflow: "hidden", overflowWrap: "break-word", flex: "none" }} variant="body2" align={isHorizontal() ? undefined : "center"} color="primary" text={props.description} />
    </Show>);
    const FirstChild = createMemo((): JSX.Element =>
        ((!Array.isArray(props.children) || props.children.length === 1) ? undefined : props.children[0]));
    const OtherChildren = createMemo((): JSX.Element =>
        ((!Array.isArray(props.children) || props.children.length === 1) ? props.children : props.children.slice(1)));
    const Buttons = createMemo((): JSX.Element => <Stack
        justifyContent="space-between"
        alignItems={fullHeight() ? "end" : "start"}
        rowGap={2}
        columnGap={2}
        flexWrap="wrap-reverse"
        direction="row"
        sx={{
            pl: isHorizontal() && alternateHeight() ? 1.5 : undefined,
            flex: (isHorizontal() && (!fullHeight() || alternateHeight())) ? "auto" : undefined,
            ...(isHorizontal() && fullscreen() && alternateHeight() && !props.fullWidth) ? {
                width: `calc( 2 * 324px + ${theme.spacing(3)} )`,
                margin: "auto",
            } : undefined,
            mt: 2,
        }}
    >
        <span>
            {props.secondaryButton}
        </span>
        <span>
            {props.primaryButton}
        </span>
    </Stack>);

    return (
        <AppFrame
            ref={frameRef}
            fullWidth={props.fullWidth}
            minContentWidth={props.minContentWidth}
            minContentHeight={props.minContentHeight || isHeightBreakPoint()}
            fullscreen={fullscreen()}
            data-testid={props.id}
            onClickInner={props.onClick}
            showFooter={props.showFooter}
            footer={<Footer setPdf={setPdf} padRight />}
        >
            <Switch fallback={
                <div
                    class="container"
                    style={{
                        "display": "flex",
                        "flex-direction": "column",
                        "flex": "auto",
                        "gap": theme.spacing(0),
                        "max-height": "100%",
                    }}
                >
                    <Head />
                    <Title />
                    <Link />
                    <Description />
                    <div
                        class="children"
                        style={{ "flex": fullHeight() ? "auto" : undefined, "display": "flex", "flex-direction": "column", "overflow": "auto" }}
                    >
                        {props.children}
                    </div>
                    <Buttons />
                    <DebugErrors errors={props.errors} />
                    <Show when={fullscreen()}>
                        <div class="footer" style={{ "text-align": "end" }}>
                            <Footer setPdf={setPdf} />
                        </div>
                    </Show>
                </div>
            }>
                <Match when={isHorizontal() && alternateHeight()}>
                    <div style={{ "height": "100%", "display": "flex", "flex-direction": "column" }}>
                        <div>
                            <Head />
                        </div>
                        <div
                            class="children"
                            style={{
                                "flex": fullHeight() ? "100%" : undefined,
                                "display": "flex",
                                "flex-direction": "row",
                                "align-content": "center",
                                "justify-content": "center",
                                "align-items": props.centerContent ? "center" : "end",
                                "justify-items": "center",

                                "gap": theme.spacing(3),
                                "overflow": "auto",
                            }}
                        >
                            <div style={{
                                "flex": "0 0 324px",
                                "padding-left": theme.spacing(1.5),
                            }}>
                                <Title />
                                <Link />
                                <Description />
                                <FirstChild />
                            </div>
                            <div style={{
                                "flex": `0 1 ${alternateWidth() ? "100%" : "324px"}`,
                                "max-height": "100%",
                                "overflow": "auto",
                                "height": fullHeight() && !props.centerContent ? "100%" : undefined,
                                "align-content": "end",
                            }}>
                                <OtherChildren/>
                            </div>
                        </div>
                        <Buttons />
                        <div class="footer" style={{ "text-align": "end" }}>
                            <Footer setPdf={setPdf} />
                        </div>
                    </div>
                </Match>
                <Match when={isHorizontal()}>
                    <div
                        class="container"
                        style={{
                            "display": "flex",
                            "overflow": "hidden",
                            "flex-direction": "row",
                            "gap": theme.spacing(2),
                            "flex": "auto",
                        }}
                    >
                        <div
                            class="panel"
                            style={{ "display": "flex", "flex-direction": "column", "width": "400px", "margin-left": theme.spacing(1.5) }}
                        >
                            <Head />
                            <Title/>
                            <Link/>
                            <Description/>
                            <Buttons/>
                            <DebugErrors errors={props.errors} />
                            <Show when={fullscreen()}>
                                <div class="footer">
                                    <Footer setPdf={setPdf} />
                                </div>
                            </Show>
                        </div>
                        <div
                            class="panel"
                            style={{ "flex": "auto", "display": "flex", "flex-direction": "column", "overflow": "auto" }}
                        >
                            {props.children}
                        </div>
                    </div>
                </Match>
            </Switch>
            {pdf() ? <PrivacyTermsModal
                pdfResource={pdf()}
                open
                onClose={handleClose}
            /> : null}
        </AppFrame>
    );
};
